<template>
  <div class="my-info">
    <div class="tab-bar">
      <div
        class="tab"
        :class="active === 'profile' ? 'active' : ''"
        @click="changeTab('profile')"
      >
        {{ L("个人资料") }}
      </div>
      <div
        class="tab"
        :class="active === 'school' ? 'active' : ''"
        @click="changeTab('school')"
      >
        {{ L("在校信息") }}
      </div>
      <div
        class="tab"
        :class="active === 'bank' ? 'active' : ''"
        @click="changeTab('bank')"
      >
        {{ L("银行信息") }}
      </div>
      <div
        class="tab"
        :class="active === 'extra' ? 'active' : ''"
        @click="changeTab('extra')"
      >
        {{ L("补充信息") }}
      </div>
      <div
        class="tab"
        :class="active === 'contact' ? 'active' : ''"
        @click="changeTab('contact')"
      >
        {{ L("紧急联系人") }}
      </div>
    </div>

    <personal-info
      v-show="active === 'profile'"
      :detail="detail"
      @refresh="refresh"
    />
    <school-info
      v-show="active === 'school'"
      :detail="detail"
      @refresh="refresh"
    />
    <bank-info v-show="active === 'bank'" :detail="detail" @refresh="refresh" />
    <extra-info
      v-show="active === 'extra'"
      :detail="detail"
      @refresh="refresh"
    />
    <contact-info
      v-show="active === 'contact'"
      :detail="detail"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import PersonalInfo from "./personal-info.vue";
import SchoolInfo from "./school-info.vue";
import BankInfo from "./bank-info.vue";
import ExtraInfo from "./extra-info.vue";
import ContactInfo from "./contact-info.vue";
import { fetchResume } from "@/api/me";
import { mapMutations } from "vuex";
export default {
  components: {
    PersonalInfo,
    SchoolInfo,
    BankInfo,
    ExtraInfo,
    ContactInfo,
  },

  data() {
    return {
      active: "",
      detail: {},
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    ...mapMutations("login", ["setResume"]),

    getList(type) {
      fetchResume().then((res) => {
        this.active = type ? type : "profile";
        this.detail = res;
        window.localStorage.setItem("resume", JSON.stringify(res));
        window.localStorage.setItem('type', res.type)
        this.setResume(res);
      });
    },
    changeTab(tab) {
      this.active = tab;
    },
    refresh(type) {
      this.getList(type);
    },
  },
};
</script>

<style lang="less" scoped>
.tab-bar {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}
.tab {
  font-weight: 400;
  color: #000000;
  opacity: 0.6;
  cursor: pointer;
  margin-right: 24px;
  padding-bottom: 12px;
}
.active {
  color: #004BB0;
  opacity: 1;
  position: relative;
}
.active::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 1.5px;
  background-color: #004BB0;
}
</style>


<style lang="less" scoped>
@media screen and (max-width: 767px) {
  .tab-bar {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
  }
  .tab-bar .tab {
    font-size: 13px;
    margin-right: 0;
    padding-bottom: 12px;
  }
}
</style>