var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form,"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('新密码')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
                 rules: [{ required: true, message: _vm.L('请输入！') }],
            } ]),expression:"[\n            'password',\n            {\n                 rules: [{ required: true, message: L('请输入！') }],\n            },\n          ]"}],attrs:{"type":"password"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('确认新密码')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password1',
            {
                 rules: [{ required: true, message: _vm.L('请输入！') }],
            } ]),expression:"[\n            'password1',\n            {\n                 rules: [{ required: true, message: L('请输入！') }],\n            },\n          ]"}],attrs:{"type":"password"}})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(_vm._s(_vm.L('保存')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }