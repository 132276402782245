import request from '../request'

// 我的简历
export function fetchResume() {
    return request({
        url: '/Api/Me/getResume',
    })
}

//更新简历
export function updateResume(data) {
    return request({
        url: '/Api/Me/updateResume',
        method: "POST",
        headers: {
            'content-type': "application/x-www-form-urlencoded"
        },
        data,
    })
}

export function updatePassword(data) {
    return request({
        url: '/Api/Me/updatePassword',
        method: "POST",
        headers: {
            'content-type': "application/x-www-form-urlencoded"
        },
        data,
    })
}

// 添加到收藏
export function addorCancelCollection(params) {
    return request({
        url: '/Api/Me/setFav',
        params,
    })
}

// 是否收藏
export function isCollect(params) {
    return request({
        url: '/Api/Me/isFav',
        params,
    })
}

// 职位投递
export function signUpJob(params) {
    return request({
        url: '/Api/Me/submitResume',
        params,
    })
}

// 活动报名
export function enrollActivity(params) {
    return request({
        url: '/Api/Me/enrollActivity',
        params,
    })
}

// 查看是否已报名
export function isRegisteredActivity(params) {
    return request({
        url: '/Api/Me/isActivitySigned',
        params
    })
}

// 我的投递
export function fetchSubmitResumeList(params) {
    return request({
        url: '/Api/Me/getSubmitResumeList',
        params,
    })
}

// 收回简历
export function backResume(params) {
    return request({
        url: '/Api/Me/withdrawResume',
        params,
    })
}

export function fetchCollectionList(params) {
    return request({
        url: '/Api/Me/getFavList',
        params,
    })
}

// 添加经历
export function addExpe(params) {
    return request({
        url: '/Api/Me/addExp',
        params,
    })
}

export function fetchExpeList(params) {
    return request({
        url: '/Api/Me/getExpList',
        params,
    })
}

// 我的活动
export function fetchMyActivityList(params) {
    return request({
        url: '/Api/Me/getActivityList',
        params,
    })
}