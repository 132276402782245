import { message } from 'ant-design-vue'
import '../../../../utils/lang'

const L = window.L;

export function checkUserInfoIntegrity(info) {

    setTimeout(() => {


        if (!info.name) {
            message.error(L("请完善 个人资料 > 姓名"));
            return;
        }
        if (!info.sex) {
            message.error(L("请完善 个人资料 > 性别"));
            return;
        }

        if (!info.email) {
            message.error(L('请完善 个人资料 > 电子邮箱'))
            return
        }
        if (!info.mobile) {
            message.error(L('请完善 个人资料 > 行动电话'))
            return
        }


        if (!info.school) {
            message.error(L('请完善 在校信息 > 学校'))
            return
        }
        if (!info.dept) {
            message.error(L('请完善 在校信息 > 学校科系'))
            return
        }
        if (!info.grade) {
            message.error(L('请完善 在校信息 > 年级'))
            return
        }


        if (!info.birthday) {
            message.error(L('请完善 补充信息 > 出生日期'))
            return
        }
        if (!info.province) {
            message.error(L('请完善 补充信息 > 大陆所在省市'))
            return
        }
        if (!info.tw_city) {
            message.error(L('请完善 补充信息 > 台湾居住城市'))
            return
        }
        if (!info.permit_account) {
            message.error(L('请完善 补充信息 > 台胞证号'))
            return
        }
        if (!info.permit_img) {
            message.error(L('请完善 补充信息 > 台胞证照片'))
            return
        }

        if (!info.contact_username) {
            message.error(L('请完善 紧急联系人 > 联系人名称'))
            return
        }

    }, 1000);

}