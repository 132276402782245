var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('学校')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'school',
              {
                initialValue: _vm.detail.school,

                rules: [{ required: true, message: _vm.L('请输入！') }],
              } ]),expression:"[\n              'school',\n              {\n                initialValue: detail.school,\n\n                rules: [{ required: true, message: L('请输入！') }],\n              },\n            ]"}],on:{"blur":function (e) { return _vm.onBlur('school', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('科系')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'dept',
              {
                initialValue: _vm.detail.dept,

                rules: [{ required: true, message: _vm.L('请输入！') }],
              } ]),expression:"[\n              'dept',\n              {\n                initialValue: detail.dept,\n\n                rules: [{ required: true, message: L('请输入！') }],\n              },\n            ]"}],on:{"blur":function (e) { return _vm.onBlur('dept', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('年级')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'grade',
              {
                initialValue: _vm.detail.grade,
                rules: [{ required: true, message: _vm.L('请输入！') }],
              } ]),expression:"[\n              'grade',\n              {\n                initialValue: detail.grade,\n                rules: [{ required: true, message: L('请输入！') }],\n              },\n            ]"}],on:{"blur":function (e) { return _vm.onBlur('grade', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('外语等级')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'language',
              {
                initialValue: _vm.detail.language,
              } ]),expression:"[\n              'language',\n              {\n                initialValue: detail.language,\n              },\n            ]"}],on:{"blur":function (e) { return _vm.onBlur('language', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('个人特长')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'profile_detail',
              {
                initialValue: _vm.detail.profile_detail,
                rules: [{ required: true, message: _vm.L('请输入！') }],
              } ]),expression:"[\n              'profile_detail',\n              {\n                initialValue: detail.profile_detail,\n                rules: [{ required: true, message: L('请输入！') }],\n              },\n            ]"}],attrs:{"rows":4},on:{"blur":function (e) { return _vm.onBlur('profile_detail', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('推荐人')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'referee',
              {
                initialValue: _vm.detail.referee,
              } ]),expression:"[\n              'referee',\n              {\n                initialValue: detail.referee,\n              },\n            ]"}],on:{"blur":function (e) { return _vm.onBlur('referee', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":6,"md":6,"sm":6}},[_c('a-form-item',{attrs:{"label":_vm.L('专业证照1')}},[_c('Uploader',{attrs:{"type":"image","path":_vm.path1 ? _vm.path1 : _vm.detail.major1_img},on:{"change":_vm.onChange1}})],1)],1),_c('a-col',{attrs:{"lg":6,"md":6,"sm":6}},[_c('a-form-item',{attrs:{"label":_vm.L('专业证照2')}},[_c('Uploader',{attrs:{"type":"image","path":_vm.path2 ? _vm.path2 : _vm.detail.major2_img},on:{"change":_vm.onChange2}})],1)],1),_c('a-col',{attrs:{"lg":6,"md":6,"sm":6}},[_c('a-form-item',{attrs:{"label":_vm.L('专业证照3')}},[_c('Uploader',{attrs:{"type":"image","path":_vm.path3 ? _vm.path3 : _vm.detail.major3_img},on:{"change":_vm.onChange3}})],1)],1),_c('a-col',{attrs:{"lg":6,"md":6,"sm":6}},[_c('a-form-item',{attrs:{"label":_vm.L('专业证照4')}},[_c('Uploader',{attrs:{"type":"image","path":_vm.path4 ? _vm.path4 : _vm.detail.major4_img},on:{"change":_vm.onChange4}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('获奖情况')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'reward_detail',
              {
                initialValue: _vm.detail.reward_detail,
              } ]),expression:"[\n              'reward_detail',\n              {\n                initialValue: detail.reward_detail,\n              },\n            ]"}],attrs:{"rows":4},on:{"blur":function (e) { return _vm.onBlur('reward_detail', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('社会实践')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'social_detail',
              {
                initialValue: _vm.detail.social_detail,
              } ]),expression:"[\n              'social_detail',\n              {\n                initialValue: detail.social_detail,\n              },\n            ]"}],attrs:{"rows":4},on:{"blur":function (e) { return _vm.onBlur('social_detail', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('擅长软件')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'soft_detail',
              {
                initialValue: _vm.detail.soft_detail,
              } ]),expression:"[\n              'soft_detail',\n              {\n                initialValue: detail.soft_detail,\n              },\n            ]"}],attrs:{"rows":4},on:{"blur":function (e) { return _vm.onBlur('soft_detail', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(_vm._s(_vm.L("保存")))]),_c('a-button',{on:{"click":_vm.preview}},[_vm._v(_vm._s(_vm.L("简历预览")))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }