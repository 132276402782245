var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form,"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('联系人名称')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'contact_username',
            {
              initialValue: _vm.detail.contact_username,
              rules: [{ required: true, message: _vm.L('请输入！') }],
            } ]),expression:"[\n            'contact_username',\n            {\n              initialValue: detail.contact_username,\n              rules: [{ required: true, message: L('请输入！') }],\n            },\n          ]"}],on:{"blur":function (e) { return _vm.onBlur('contact_username', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('联系人关系')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'contact_relationship',
            {
              initialValue: _vm.detail.contact_relationship,
              rules: [{ required: true, message: _vm.L('请输入！') }],
            } ]),expression:"[\n            'contact_relationship',\n            {\n              initialValue: detail.contact_relationship,\n              rules: [{ required: true, message: L('请输入！') }],\n            },\n          ]"}],on:{"blur":function (e) { return _vm.onBlur('contact_relationship', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('联系人行动电话')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'contact_mobile',
            {
              initialValue: _vm.detail.contact_mobile,
              rules: [{ required: true, message: _vm.L('请输入！') }],
            } ]),expression:"[\n            'contact_mobile',\n            {\n              initialValue: detail.contact_mobile,\n              rules: [{ required: true, message: L('请输入！') }],\n            },\n          ]"}],on:{"blur":function (e) { return _vm.onBlur('contact_mobile', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(_vm._s(_vm.L("保存")))]),_c('a-button',{on:{"click":_vm.preview}},[_vm._v(_vm._s(_vm.L("简历预览")))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }