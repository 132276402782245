<template>
  <a-form :form="form" :colon="false" @submit="handleSubmit">
    <a-row :gutter="24">
      <a-col :lg="12" :md="12" :sm="24">
        <a-form-item :label="L('联系人名称')">
          <a-input
            v-decorator="[
              'contact_username',
              {
                initialValue: detail.contact_username,
                rules: [{ required: true, message: L('请输入！') }],
              },
            ]"
            @blur="(e) => onBlur('contact_username', e.target.value)"
          />
        </a-form-item>
      </a-col>
      <a-col :lg="12" :md="12" :sm="24">
        <a-form-item :label="L('联系人关系')">
          <a-input
            v-decorator="[
              'contact_relationship',
              {
                initialValue: detail.contact_relationship,
                rules: [{ required: true, message: L('请输入！') }],
              },
            ]"
            @blur="(e) => onBlur('contact_relationship', e.target.value)"
          />
        </a-form-item>
      </a-col>
      <a-col :lg="12" :md="12" :sm="24">
        <a-form-item :label="L('联系人行动电话')">
          <a-input
            v-decorator="[
              'contact_mobile',
              {
                initialValue: detail.contact_mobile,
                rules: [{ required: true, message: L('请输入！') }],
              },
            ]"
            @blur="(e) => onBlur('contact_mobile', e.target.value)"
          />
        </a-form-item>
      </a-col>

      <a-col :lg="24" :md="24" :sm="24">
        <a-form-item>
          <a-space>
            <a-button type="primary" html-type="submit" :loading="loading">{{
              L("保存")
            }}</a-button>
            <a-button @click="preview">{{ L("简历预览") }}</a-button>
          </a-space>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>


<script>
import { updateResume } from "@/api/me";
import qs from "qs";
import { checkUserInfoIntegrity } from "./util";
export default {
  props: {
    detail: {},
  },
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
    };
  },

  methods: {
    onBlur(type, value) {
      console.log(type, value);
      if (this.detail[type] === value) {
        return;
      }

      updateResume(
        qs.stringify({
          ...this.detail,
          [type]: value,
        })
      ).then((res) => {
        if (res._status === 1) {
          this.$message.success(this.L(res.info));
          this.$emit("refresh", "contact");
          // checkUserInfoIntegrity(this.detail);
        }
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          console.log(values);
          updateResume(
            qs.stringify({
              ...this.detail,
              ...values,
            })
          )
            .then((res) => {
              if (res._status === 1) {
                this.$message.success(this.L(res.info));
                this.$emit("refresh", "contact");
                checkUserInfoIntegrity(this.detail);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    preview() {
      const str = window.localStorage.getItem("resume");
      if (str) {
        const resume = JSON.parse(str);
        window.open("/portal/#/resume?uuid=" + resume.uuid, "_blank");
      } else {
        this.$message.error("请先登录");
      }
    },
  },
};
</script>