var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('银行名称')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'bank_name',
            {
              initialValue: _vm.detail.bank_name,
            } ]),expression:"[\n            'bank_name',\n            {\n              initialValue: detail.bank_name,\n            },\n          ]"}],on:{"blur":function (e) { return _vm.onBlur('bank_name', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('银行代码')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'bank_code',
            {
              initialValue: _vm.detail.bank_code,
            } ]),expression:"[\n            'bank_code',\n            {\n              initialValue: detail.bank_code,\n            },\n          ]"}],on:{"blur":function (e) { return _vm.onBlur('bank_code', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('银行户名')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'bank_username',
            {
              initialValue: _vm.detail.bank_username,
            } ]),expression:"[\n            'bank_username',\n            {\n              initialValue: detail.bank_username,\n            },\n          ]"}],on:{"blur":function (e) { return _vm.onBlur('bank_username', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('银行账号')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'bank_account',
            {
              initialValue: _vm.detail.bank_account,
            } ]),expression:"[\n            'bank_account',\n            {\n              initialValue: detail.bank_account,\n            },\n          ]"}],on:{"blur":function (e) { return _vm.onBlur('bank_account', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('是否有中国信托的银行账户')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'is_trust_account',
            {
              initialValue: _vm.detail.is_trust_account,
            } ]),expression:"[\n            'is_trust_account',\n            {\n              initialValue: detail.is_trust_account,\n            },\n          ]"}],on:{"change":function (value) { return _vm.onBlur('is_trust_account', value); }}},_vm._l(([
              { name: _vm.L('是'), value: '1' },
              { name: _vm.L('否'), value: '0' } ]),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('银行存折封面（需包含户名及账号）')}},[_c('div',{staticStyle:{"width":"200px"}},[_c('Uploader',{attrs:{"type":"image","path":_vm.path ? _vm.path : _vm.detail.bank_img},on:{"change":_vm.onChange}})],1)])],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(_vm._s(_vm.L("保存")))]),_c('a-button',{on:{"click":_vm.preview}},[_vm._v(_vm._s(_vm.L("简历预览")))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }