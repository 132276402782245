<template>
  <div>
    <a-form :form="form" :colon="false" @submit="handleSubmit">
      <a-row :gutter="24">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('英文名')">
            <a-input v-decorator="[
              'english_name',
              {
                initialValue: detail.english_name,
              },
            ]" @blur="(e) => onBlur('english_name', e.target.value)" />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('出生日期')">
            <a-date-picker style="width: 100%" v-decorator="[
              'birthday',
              {
                initialValue: detailObj.birthday,
                rules: [{ required: true, message: L('请选择！') }],
              },
            ]" @change="onSelectDate" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('台湾居住城市')">
            <a-select style="width: 100%" v-decorator="[
              'tw_city',
              {
                initialValue: detail.tw_city,
                rules: [{ required: true, message: L('请选择！') }],
              },
            ]" @change="(value) => onBlur('tw_city', value)">
              <a-select-option v-for="item in [
                { name: L('台北'), value: '台北' },
                { name: L('桃园'), value: '桃园' },
                { name: L('台中'), value: '台中' },
                { name: L('台南'), value: '台南' },
                { name: L('高雄'), value: '高雄' },
                { name: L('基隆'), value: '基隆' },
                { name: L('新竹'), value: '新竹' },
                { name: L('嘉义'), value: '嘉义' },
                { name: L('宜兰'), value: '宜兰' },
                { name: L('苗栗'), value: '苗栗' },
                { name: L('彰化'), value: '彰化' },
                { name: L('南投'), value: '南投' },
                { name: L('云林'), value: '云林' },
                { name: L('屏东'), value: '屏东' },
                { name: L('台东'), value: '台东' },
                { name: L('花莲'), value: '花莲' },
                { name: L('澎湖'), value: '澎湖' },
                { name: L('新北'), value: '新北' },
                { name: L('金门'), value: '金门' },
              ]" :key="item.value" :value="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('大陆所在省市')">
            <a-cascader :options="tree" placeholder="" v-decorator="[
              'cityList',
              {
                initialValue: detailObj.cityList,
                rules: [{ required: true, message: L('请选择！') }],
              },
            ]" @change="onSelectCity" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('邮递区号')">
            <a-input v-decorator="[
              'zipcode',
              {
                initialValue: detail.zipcode,
              },
            ]" @blur="(e) => onBlur('zipcode', e.target.value)" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('详细地址')">
            <a-input v-decorator="[
              'address',
              {
                initialValue: detail.address,
              },
            ]" @blur="(e) => onBlur('address', e.target.value)" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('LINE帐号')">
            <a-input v-decorator="[
              'line',
              {
                initialValue: detail.line,
              },
            ]" @blur="(e) => onBlur('line', e.target.value)" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('微信账号')">
            <a-input v-decorator="[
              'wechat',
              {
                initialValue: detail.wechat,
              },
            ]" @blur="(e) => onBlur('wechat', e.target.value)" />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('脸书账号')">
            <a-input v-decorator="[
              'facebook',
              {
                initialValue: detail.facebook,
              },
            ]" @blur="(e) => onBlur('facebook', e.target.value)" />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label="L('特殊才艺')">
            <a-textarea :rows="4" v-decorator="[
              'special_skill_detail',
              {
                initialValue: detail.special_skill_detail,
              },
            ]" @blur="(e) => onBlur('special_skill_detail', e.target.value)" />
          </a-form-item>
        </a-col>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label="L('特殊饮食')">
            <a-textarea :rows="4" v-decorator="[
              'special_diet_detail',
              {
                initialValue: detail.special_diet_detail,
              },
            ]" @blur="(e) => onBlur('special_diet_detail', e.target.value)" />
          </a-form-item>
        </a-col>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label="L('特殊病史')">
            <a-textarea :rows="4" v-decorator="[
              'special_medical_detail',
              {
                initialValue: detail.special_medical_detail,
              },
            ]" @blur="(e) => onBlur('special_medical_detail', e.target.value)" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('台胞证号')">
            <a-input v-decorator="[
              'permit_account',
              {
                initialValue: detail.permit_account,
                rules: [
                  { required: true, message: L('请输入英文字母和数字！') },
                  { validator: checkCharacterAndNumber, trigger: 'blur' },
                ],
              },
            ]" @blur="(e) => onBlur('permit_account', e.target.value)" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('台胞证照片（必须）')">
            <div style="width: 200px">
              <Uploader type="image" @change="onChange" :path="path ? path : detail.permit_img" />
            </div>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit" :loading="loading">{{
                L("保存")
              }}</a-button>
              <a-button @click="preview">{{ L("简历预览") }}</a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import Uploader from "@/components/uploader";
import { updateResume } from "@/api/me";
import tree from "@/utils/address";
import moment from "moment";
import qs from "qs";
import { checkUserInfoIntegrity } from "./util";
export default {
  components: {
    Uploader,
  },

  props: {
    detail: {},
  },

  data() {
    return {
      tree,
      form: this.$form.createForm(this),
      loading: false,
      path: "",
    };
  },

  computed: {
    detailObj() {
      const { birthday, province, city, area } = this.detail;
      let cityList = [];
      if (province) {
        cityList.push(province);
      }
      if (city) {
        cityList.push(city);
      }
      if (area) {
        cityList.push(area);
      }
      return {
        ...this.detail,
        birthday: birthday ? moment(birthday) : null,
        cityList,
      };
    },
  },

  methods: {
    onBlur(type, value) {
      console.log(type, value);

      if (this.detail[type] === value) {
        return;
      }

      updateResume(
        qs.stringify({
          ...this.detail,
          [type]: value,
        })
      ).then((res) => {
        if (res._status === 1) {
          this.$message.success(this.L(res.info));
          this.$emit("refresh", "extra");
          // checkUserInfoIntegrity(this.detail);
        }
      });
    },

    onSelectCity(list) {
      if (list && list.length > 0) {
        const [province, city, area] = list;
        updateResume(
          qs.stringify({
            ...this.detail,
            cityList: null,
            province,
            city,
            area,
          })
        ).then((res) => {
          if (res._status === 1) {
            this.$message.success(this.L(res.info));
            this.$emit("refresh", "extra");
            // checkUserInfoIntegrity(this.detail);
          }
        });
      }
    },

    onSelectDate(e) {
      if (e) {
        updateResume(
          qs.stringify({
            ...this.detail,
            birthday: e.format("YYYY-MM-DD"),
          })
        ).then((res) => {
          if (res._status === 1) {
            this.$message.success(this.L(res.info));
            this.$emit("refresh", "extra");
            // checkUserInfoIntegrity(this.detail);
          }
        });
      }
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {

          if (!this.path && !this.detail.permit_img) {
            this.$message.error(this.L('请上传台胞证照片'));
            return;
          }

          this.loading = true;
          console.log(values);

          const { birthday, cityList } = values;
          const [province, city, area] = cityList;

          updateResume(
            qs.stringify({
              ...this.detail,
              ...values,
              cityList: null,
              birthday: birthday.format("YYYY-MM-DD"),
              province,
              city,
              area,
              permit_img: this.path ? this.path : this.detail.permit_img,
            })
          )
            .then((res) => {
              if (res._status === 1) {
                this.$message.success(this.L(res.info));
                this.$emit("refresh", "extra");
                checkUserInfoIntegrity(this.detail);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    onChange(path) {
      this.path = path;
      this.onBlur("permit_img", path);
    },

    preview() {
      const str = window.localStorage.getItem("resume");
      if (str) {
        const resume = JSON.parse(str);
        window.open("/portal/#/resume?uuid=" + resume.uuid, "_blank");
      } else {
        this.$message.error("请先登录");
      }
    },

    checkCharacterAndNumber(rule, value, callback) {
      if (value) {
        if (!/^[a-z0-9A-Z]+$/gi.test(value)) {
          callback(new Error(window.L("请输入英文字母和数字")));
        }
      }
      callback();
    },
  },
};
</script>