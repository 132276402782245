<template>
    <a-form :form="form" :colon="false" @submit="handleSubmit">
      <a-row :gutter="24">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('新密码')">
              <a-input
              type='password'
              v-decorator="[
                'password',
                {
                     rules: [{ required: true, message: L('请输入！') }],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('确认新密码')">
            <a-input
              type='password'

              v-decorator="[
                'password1',
                {
                     rules: [{ required: true, message: L('请输入！') }],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      <a-col :span='24'>
          <a-form-item >
          <a-button type="primary" html-type="submit" :loading="loading"
            >{{L('保存')}}</a-button
          >
          </a-form-item>
        </a-col>

      </a-row>
    </a-form>


    </template>
      

<script>
  import { updatePassword } from '@/api/me'
  import qs from 'qs'
export default {
  data() {
    return {
            form: this.$form.createForm(this),
      loading: false,
    };
  },

  methods: {

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          console.log(values);
          updatePassword(
            qs.stringify({
              password: values.password,
            })
          )
            .then((res) => {
              if (res._status === 1) {
                this.$message.success(this.L(res.info))
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

  },
};
</script>
