<template>
  <div class="my-table">
    <table>
      <tr>
        <th style="flex: 2">{{ L("活动名称") }}</th>
        <th>{{ L("报名时间") }}</th>
        <th>{{ L("签到时间") }}</th>
        <th>{{ L("签到图片") }}</th>
      </tr>
      <template v-if="list.length > 0">
        <tr v-for="(item, index) in list" :key="index">
          <td style="flex: 2">
            <template>
              <router-link :to="`/activity/detail?uuid=${item.uuid}`">
                <span style="font-weight: bold">
                  {{ L(item.name) }}
                </span>
              </router-link>
            </template>
          </td>
          <td>
            {{ item.enroll_at | timeTrans("yyyy-MM-dd") }}
          </td>
          <td>
            <span v-if="parseInt(item.sign_at)">
              {{ item.sign_at | timeTrans("yyyy-MM-dd") }}
            </span>
          </td>
          <td>
              <img class="sign-image" v-if="item.sign_img" :src="item.sign_img" alt="" @click="openImage(item.sign_img)">
          </td>
        </tr>
      </template>
      <tr v-else>
        <td rowspan="3">
          <a-empty />
        </td>
      </tr>
    </table>

    <div class="center" v-if="total > 10">
      <a-pagination v-model="current" :pageSize="pageSize" :total="total" />
    </div>
  </div>
</template>

<script>
import { fetchMyActivityList } from "@/api/me";

export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  watch: {
    current() {
      this.getList();
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize } = this;
      fetchMyActivityList({
        page: current,
        rows: pageSize,
      }).then((res) => {
        console.log("res", res);
        if (Array.isArray(res.list)) {
          this.list = res.list;
          this.total = res.total ? parseInt(res.total) : 0;
        }
      });
    },

    openImage(path) {
      window.open(path, '_blank')
    }
  },
};
</script>

<style scoped>
.sign-image {
  width: 40px;
  cursor: pointer;
}
</style>