<template>
  <div class="my-table">
    <a-button
      type="primary"
      style="margin-bottom: 12px"
      @click="visible = true"
      >{{ L("上传") }}</a-button
    >

    <a-modal
      :title="L('上传')"
      :visible="visible"
      @cancel="visible = false"
      @ok="ok"
      :confirmLoading="confirmLoading"
    >
      <a-form>
        <a-form-item :label="L('标题')">
          <a-input v-model="title" :placeholder="L('请输入')" />
        </a-form-item>
        <a-form-item :label="L('文件')">
          <Uploader type="file" @change="onFileChange" :path="path" />
        </a-form-item>
      </a-form>
    </a-modal>

    <div class="my-table">
      <table>
        <tr>
          <th>{{ L("档案名") }}</th>
          <th>{{ L("上传日期") }}</th>
          <th>{{ L("操作") }}</th>
        </tr>
        <template v-if="list.length > 0">
          <tr v-for="(item, index) in list" :key="index">
            <td>{{ L(item.name) }}</td>
            <td>{{ item.create_at | timeTrans("yyyy-MM-dd") }}</td>
            <td>
              <a
                target="_blank"
                download
                style="color: #004BB0; font-weight: bold; cursor: pointer"
                :href="item.url"
              >
                {{ L("下载") }}
              </a>
            </td>
          </tr>
        </template>
        <tr v-else>
          <td rowspan="3">
            <a-empty />
          </td>
        </tr>
      </table>
    </div>

    <div class="center" v-if="total > 10">
      <a-pagination v-model="current" :pageSize="pageSize" :total="total" />
    </div>
  </div>
</template>

<script>
import Uploader from "@/components/uploader";
import { addExpe, fetchExpeList } from "@/api/me";
export default {
  components: {
    Uploader,
  },

  data() {
    return {
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      visible: false,
      confirmLoading: false,
      title: "",
      path: "",
    };
  },

  watch: {
    current() {
      this.getList();
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize } = this;
      fetchExpeList({
        page: current,
        rows: pageSize,
      }).then((res) => {
        if (Array.isArray(res.list)) {
          this.list = res.list;
          this.total = parseInt(res.total);
        }
      });
    },

    onFileChange(path) {
      this.path = path;
    },

    ok() {
      if (!this.title) {
        this.$message.error("请填写标题");
        return;
      }
      if (!this.path) {
        this.$message.error("请上传档案");
        return;
      }
      this.confirmLoading = true;
      addExpe({
        name: this.title,
        url: this.path,
      })
        .then((res) => {
          if (res._status === 1) {
            this.$message.success(this.L(res.info));
            this.visible = false;
          }
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
  },
};
</script>