var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"width":"134px"}},[_c('Uploader',{attrs:{"type":"image","round":true,"title":"建议使用证件照/职业照，可以用学生证或其他证件照片，尽量不要用生活照","path":_vm.avatarPath ? _vm.avatarPath : _vm.detail.avatar_img},on:{"change":_vm.onChangeAvatar}})],1),_c('a-form',{attrs:{"form":_vm.form,"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('姓名')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                initialValue: _vm.detail.name,
                rules: [{ required: true, message: _vm.L('请输入！') }],
              } ]),expression:"[\n              'name',\n              {\n                initialValue: detail.name,\n                rules: [{ required: true, message: L('请输入！') }],\n              },\n            ]"}],on:{"blur":function (e) { return _vm.onBlur('name', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('性别')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'sex',
              {
                initialValue: _vm.detail.sex,
                rules: [{ required: true, message: _vm.L('请选择！') }],
              } ]),expression:"[\n              'sex',\n              {\n                initialValue: detail.sex,\n                rules: [{ required: true, message: L('请选择！') }],\n              },\n            ]"}],on:{"change":function (value) { return _vm.onBlur('sex', value); }}},_vm._l(([
                { name: _vm.L('男'), value: '0' },
                { name: _vm.L('女'), value: '1' } ]),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('电子邮件')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              {
                initialValue: _vm.detail.email,

                rules: [{ required: true, message: _vm.L('请输入！') }],
              } ]),expression:"[\n              'email',\n              {\n                initialValue: detail.email,\n\n                rules: [{ required: true, message: L('请输入！') }],\n              },\n            ]"}],attrs:{"disabled":""},on:{"blur":function (e) { return _vm.onBlur('email', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('行动电话')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'mobile',
              {
                initialValue: _vm.detail.mobile,

                rules: [{ required: true, message: _vm.L('请输入！') }],
              } ]),expression:"[\n              'mobile',\n              {\n                initialValue: detail.mobile,\n\n                rules: [{ required: true, message: L('请输入！') }],\n              },\n            ]"}],on:{"blur":function (e) { return _vm.onBlur('mobile', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('去往大陆次数')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'mainland_num',
              {
                initialValue: _vm.detail.mainland_num,
              } ]),expression:"[\n              'mainland_num',\n              {\n                initialValue: detail.mainland_num,\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"min":0,"max":99},on:{"blur":function (e) { return _vm.onBlur('mainland_num', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('学生类别')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'type',
              {
                initialValue: _vm.detail.type,
              } ]),expression:"[\n              'type',\n              {\n                initialValue: detail.type,\n              },\n            ]"}],on:{"blur":function (value) { return _vm.onBlur('type', value); }}},[_c('a-select-option',{attrs:{"value":"intern"}},[_vm._v("在读")]),_c('a-select-option',{attrs:{"value":"fresh"}},[_vm._v("应届生")])],1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('两岸交流活动经验')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'exchange_detail',
              {
                initialValue: _vm.detail.exchange_detail,
              } ]),expression:"[\n              'exchange_detail',\n              {\n                initialValue: detail.exchange_detail,\n              },\n            ]"}],on:{"blur":function (e) { return _vm.onBlur('exchange_detail', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('实习或工作经历')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'exp_detail',
              {
                initialValue: _vm.detail.exp_detail,
                rules: [{ required: true, message: _vm.L('请输入') }],
              } ]),expression:"[\n              'exp_detail',\n              {\n                initialValue: detail.exp_detail,\n                rules: [{ required: true, message: L('请输入') }],\n              },\n            ]"}],on:{"blur":function (e) { return _vm.onBlur('exp_detail', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('参与本次实习动机')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'motive_detail',
              {
                initialValue: _vm.detail.motive_detail,
                rules: [{ required: true, message: _vm.L('请输入') }],
              } ]),expression:"[\n              'motive_detail',\n              {\n                initialValue: detail.motive_detail,\n                rules: [{ required: true, message: L('请输入') }],\n              },\n            ]"}],on:{"blur":function (e) { return _vm.onBlur('motive_detail', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('对两岸关系的看法')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'viewpoint_detail',
              {
                initialValue: _vm.detail.viewpoint_detail,
              } ]),expression:"[\n              'viewpoint_detail',\n              {\n                initialValue: detail.viewpoint_detail,\n              },\n            ]"}],on:{"blur":function (e) { return _vm.onBlur('viewpoint_detail', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('是否愿意前往大陆就学/创业')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'is_will_stay',
              {
                initialValue: _vm.detail.is_will_stay,
              } ]),expression:"[\n              'is_will_stay',\n              {\n                initialValue: detail.is_will_stay,\n              },\n            ]"}],staticStyle:{"width":"100%"},on:{"change":function (value) { return _vm.onBlur('is_will_stay', value); }}},_vm._l(([
                { name: _vm.L('是'), value: '1' },
                { name: _vm.L('否'), value: '0' } ]),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('是否有意愿担任本活动干部')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'is_will_leader',
              {
                initialValue: _vm.detail.is_will_leader,
              } ]),expression:"[\n              'is_will_leader',\n              {\n                initialValue: detail.is_will_leader,\n              },\n            ]"}],staticStyle:{"width":"100%"},on:{"change":function (value) { return _vm.onBlur('is_will_leader', value); }}},_vm._l(([
                { name: _vm.L('是'), value: '1' },
                { name: _vm.L('否'), value: '0' } ]),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('如何得知此活动讯息')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'howto',
              {
                initialValue: _vm.detail.howto ? _vm.detail.howto : _vm.activity_from,
                rules: [{ required: true, message: _vm.L('请选择！') }],
              } ]),expression:"[\n              'howto',\n              {\n                initialValue: detail.howto ? detail.howto : activity_from,\n                rules: [{ required: true, message: L('请选择！') }],\n              },\n            ]"}],staticStyle:{"width":"100%"},on:{"change":function (value) { return _vm.onBlur('howto', value); }}},_vm._l((_vm.activityList),function(item){return _c('a-select-option',{key:item,attrs:{"value":item}},[_vm._v(" "+_vm._s(_vm.L(item))+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('备注')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'remarks',
              {
                initialValue: _vm.detail.remarks,
              } ]),expression:"[\n              'remarks',\n              {\n                initialValue: detail.remarks,\n              },\n            ]"}],on:{"blur":function (e) { return _vm.onBlur('remarks', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('附件')}},[_c('Uploader',{attrs:{"type":"file","path":_vm.filePath ? _vm.filePath : _vm.detail.attach_file},on:{"change":_vm.onChangeFile}}),_c('div',{staticClass:"tip"},[_vm._v("建议上传zip等压缩文件")])],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(_vm._s(_vm.L("保存")))]),_c('a-button',{on:{"click":_vm.preview}},[_vm._v(_vm._s(_vm.L("简历预览")))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }