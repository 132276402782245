<template>
  <div>
    <input ref="upload" style="display: none" type="file" @change="onChange" />
    <div class="image-uploader" v-if="type === 'image'" @click="onClick">
      <div class="loading" v-if="loading">
        <a-spin />
      </div>
      <template v-else>
        <template v-if="round">
          <img
            v-if="path"
            :src="$imgCrop(path, 100, 100, 100, 100)"
            alt=""
            :class="round ? 'round' : ''"
          />
          <img
            v-else
            :src="
              $imgCrop(
                'https://s.upapi.cn/2022/03/13/7982c2127d78004abdea6ffa02a5b53e/default-id.png',
                100,
                100,
                100,
                100
              )
            "
            :class="round ? 'round' : ''"
          />
        </template>
        <template v-else>
          <img v-if="path" :src="$imgCrop(path, 480, 260, 480, 260)" alt="" />
          <img
            v-else
            :src="
              $imgCrop(
                'https://s.upapi.cn/2022/03/13/7982c2127d78004abdea6ffa02a5b53e/default-id.png',
                480,
                260,
                480,
                260
              )
            "
          />
        </template>
        <div class="title">{{ L(title) }}</div>
        <div>{{ L("档案大小4MB以内") }}</div>
      </template>
    </div>
    <a-button
      v-if="type === 'file'"
      :loading="loading"
      icon="upload"
      @click="onClick"
    >
      {{ path ? L("重新上传") : L("上传") }}
    </a-button>
  </div>
</template>

<script>
import { uploadFile } from "@/api/upload";

export default {
  props: {
    type: {
      type: String,
      default: "image", // file
    },
    path: {
      type: String,
    },
    round: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "点击新增照片",
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    onClick() {
      this.$refs.upload.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("file", files[i]);
      }

      this.loading = true;

      uploadFile(data)
        .then((res) => {
          console.log("upload res", res);
          if (res._status === 1) {
            this.$message.success(this.L("上传成功"));
            this.$emit("change", res.file.path);
          }
        })
        .finally(() => {
          this.loading = false;
          e.target.value = null;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.image-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  line-height: 1.5em;
}
.image-uploader img {
  width: 100%;
  margin-bottom: 10px;
}
.round {
  border-radius: 50%;
}
.image-uploader .title {
  color: #004BB0;
  margin-bottom: 2px;
}
.loading {
  height: 240px;
}
</style>