<template>
  <div class="my-table">
    <table>
      <tr>
        <th style="flex: 2">{{ L("岗位") }}</th>
        <th style="flex: 2">{{ L("公司") }}</th>
        <th>{{ L("报名时间") }}</th>
        <th>{{ L("状态") }}</th>
        <th>{{ L("操作") }}</th>
      </tr>
      <template v-if="list.length > 0">
        <tr v-for="(item, index) in list" :key="index">
          <td style="flex: 2">
            <router-link :to="`/job-detail?uuid=${item.job_uuid}`">
              <span style="font-weight: bold">
                {{ item.job_name }}
              </span>
            </router-link>
          </td>
          <td style="flex: 2">
            <router-link :to="`/company-detail?uuid=${item.company_uuid}`">
              <span style="font-weight: bold">
                {{ item.company_name }}
              </span>
            </router-link>
          </td>
          <td>{{ item.create_at | timeTrans("yyyy-MM-dd") }}</td>
          <td>
            <span
              style="color: #2eb792; font-weight: bold"
              v-if="item.status === '1'"
            >
              {{ L("投递成功") }}
            </span>
            <span
              style="color: #ff0000; font-weight: bold"
              v-if="item.status === '0'"
            >
              {{ L("未入围") }}
            </span>
            <span
              style="color: #004BB0; font-weight: bold"
              v-if="item.status === '1' && item.is_selected==1"
            >
              {{ L("已入围") }}
            </span>
          </td>
         <td>
            <a href="#" style="color: #3c3c3c" @click.prevent="removeText(item)">是否收回</a>
          </td>
        </tr>
      </template>
      <tr v-else>
        <td rowspan="4">
          <a-empty />
        </td>
      </tr>
    </table>

    <div class="center" v-if="total > 10">
      <a-pagination v-model="current" :pageSize="pageSize" :total="total" />
    </div>
  </div>
</template>

<script>
import { fetchSubmitResumeList, backResume } from "@/api/me";

export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  watch: {
    current() {
      this.getList();
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize } = this;
      fetchSubmitResumeList({
        page: current,
        rows: pageSize,
      }).then((res) => {
        console.log("res", res);
        if (Array.isArray(res.list)) {
          this.list = res.list;
          this.total = parseInt(res.total);
        }
      });
    },

        removeText(item) {
      const that = this;

      this.$confirm({
        title: this.L( "确认要收回简历吗？"),
        onOk() {
          backResume({
            job_id: item.job_id,
          }).then(() => {
            that.getList();
          });
        },
      });

    }
  },
};
</script>