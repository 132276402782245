<template>
  <div>
    <a-form :form="form" :colon="false" @submit="handleSubmit">
      <a-row :gutter="24">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('学校')">
            <a-input
              v-decorator="[
                'school',
                {
                  initialValue: detail.school,

                  rules: [{ required: true, message: L('请输入！') }],
                },
              ]"
              @blur="(e) => onBlur('school', e.target.value)"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('科系')">
            <a-input
              v-decorator="[
                'dept',
                {
                  initialValue: detail.dept,

                  rules: [{ required: true, message: L('请输入！') }],
                },
              ]"
              @blur="(e) => onBlur('dept', e.target.value)"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('年级')">
            <a-input
              v-decorator="[
                'grade',
                {
                  initialValue: detail.grade,
                  rules: [{ required: true, message: L('请输入！') }],
                },
              ]"
              @blur="(e) => onBlur('grade', e.target.value)"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('外语等级')">
            <a-input
              v-decorator="[
                'language',
                {
                  initialValue: detail.language,
                },
              ]"
              @blur="(e) => onBlur('language', e.target.value)"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label="L('个人特长')">
            <a-textarea
              :rows="4"
              v-decorator="[
                'profile_detail',
                {
                  initialValue: detail.profile_detail,
                  rules: [{ required: true, message: L('请输入！') }],
                },
              ]"
              @blur="(e) => onBlur('profile_detail', e.target.value)"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label="L('推荐人')">
            <a-input
              v-decorator="[
                'referee',
                {
                  initialValue: detail.referee,
                },
              ]"
              @blur="(e) => onBlur('referee', e.target.value)"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="6" :md="6" :sm="6">
          <a-form-item :label="L('专业证照1')">
            <Uploader
              type="image"
              @change="onChange1"
              :path="path1 ? path1 : detail.major1_img"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="6" :sm="6">
          <a-form-item :label="L('专业证照2')">
            <Uploader
              type="image"
              @change="onChange2"
              :path="path2 ? path2 : detail.major2_img"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="6" :sm="6">
          <a-form-item :label="L('专业证照3')">
            <Uploader
              type="image"
              @change="onChange3"
              :path="path3 ? path3 : detail.major3_img"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="6" :sm="6">
          <a-form-item :label="L('专业证照4')">
            <Uploader
              type="image"
              @change="onChange4"
              :path="path4 ? path4 : detail.major4_img"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label="L('获奖情况')">
            <a-textarea
              :rows="4"
              v-decorator="[
                'reward_detail',
                {
                  initialValue: detail.reward_detail,
                },
              ]"
              @blur="(e) => onBlur('reward_detail', e.target.value)"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label="L('社会实践')">
            <a-textarea
              :rows="4"
              v-decorator="[
                'social_detail',
                {
                  initialValue: detail.social_detail,
                },
              ]"
              @blur="(e) => onBlur('social_detail', e.target.value)"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label="L('擅长软件')">
            <a-textarea
              :rows="4"
              v-decorator="[
                'soft_detail',
                {
                  initialValue: detail.soft_detail,
                },
              ]"
              @blur="(e) => onBlur('soft_detail', e.target.value)"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit" :loading="loading">{{
                L("保存")
              }}</a-button>
              <a-button @click="preview">{{ L("简历预览") }}</a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>


<script>
import Uploader from "@/components/uploader";
import { updateResume } from "@/api/me";
import { checkUserInfoIntegrity } from "./util";
import qs from "qs";
export default {
  components: {
    Uploader,
  },

  props: {
    detail: {},
  },
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      path1: "",
      path2: "",
      path3: "",
      path4: "",
    };
  },

  methods: {
    onBlur(type, value) {
      console.log(type, value);

      if (this.detail[type] === value) {
        return;
      }

      updateResume(
        qs.stringify({
          ...this.detail,
          [type]: value,
        })
      ).then((res) => {
        if (res._status === 1) {
          this.$message.success(this.L(res.info));
          this.$emit("refresh", "school");
          // checkUserInfoIntegrity(this.detail);
        }
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          console.log(values);
          updateResume(
            qs.stringify({
              ...this.detail,
              ...values,
              major1_img: this.path1 ? this.path1 : this.detail.major1_img,
              major2_img: this.path2 ? this.path2 : this.detail.major2_img,
              major3_img: this.path3 ? this.path3 : this.detail.major3_img,
              major4_img: this.path4 ? this.path4 : this.detail.major4_img,
            })
          )
            .then((res) => {
              if (res._status === 1) {
                this.$message.success(this.L(res.info));
                this.$emit("refresh", "school");

                checkUserInfoIntegrity(this.detail);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    onChange1(path) {
      this.path1 = path;
      this.onBlur("major1_img", path);
    },
    onChange2(path) {
      this.path2 = path;
      this.onBlur("major2_img", path);
    },
    onChange3(path) {
      this.path3 = path;
      this.onBlur("major3_img", path);
    },
    onChange4(path) {
      this.path4 = path;
      this.onBlur("major4_img", path);
    },
    preview() {
      const str = window.localStorage.getItem("resume");
      if (str) {
        const resume = JSON.parse(str);
        window.open("/portal/#/resume?uuid=" + resume.uuid, "_blank");
      } else {
        this.$message.error("请先登录");
      }
    },
  },
};
</script>