var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('英文名')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'english_name',
            {
              initialValue: _vm.detail.english_name,
            } ]),expression:"[\n            'english_name',\n            {\n              initialValue: detail.english_name,\n            },\n          ]"}],on:{"blur":function (e) { return _vm.onBlur('english_name', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('出生日期')}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'birthday',
            {
              initialValue: _vm.detailObj.birthday,
              rules: [{ required: true, message: _vm.L('请选择！') }],
            } ]),expression:"[\n            'birthday',\n            {\n              initialValue: detailObj.birthday,\n              rules: [{ required: true, message: L('请选择！') }],\n            },\n          ]"}],staticStyle:{"width":"100%"},on:{"change":_vm.onSelectDate}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('台湾居住城市')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'tw_city',
            {
              initialValue: _vm.detail.tw_city,
              rules: [{ required: true, message: _vm.L('请选择！') }],
            } ]),expression:"[\n            'tw_city',\n            {\n              initialValue: detail.tw_city,\n              rules: [{ required: true, message: L('请选择！') }],\n            },\n          ]"}],staticStyle:{"width":"100%"},on:{"change":function (value) { return _vm.onBlur('tw_city', value); }}},_vm._l(([
              { name: _vm.L('台北'), value: '台北' },
              { name: _vm.L('桃园'), value: '桃园' },
              { name: _vm.L('台中'), value: '台中' },
              { name: _vm.L('台南'), value: '台南' },
              { name: _vm.L('高雄'), value: '高雄' },
              { name: _vm.L('基隆'), value: '基隆' },
              { name: _vm.L('新竹'), value: '新竹' },
              { name: _vm.L('嘉义'), value: '嘉义' },
              { name: _vm.L('宜兰'), value: '宜兰' },
              { name: _vm.L('苗栗'), value: '苗栗' },
              { name: _vm.L('彰化'), value: '彰化' },
              { name: _vm.L('南投'), value: '南投' },
              { name: _vm.L('云林'), value: '云林' },
              { name: _vm.L('屏东'), value: '屏东' },
              { name: _vm.L('台东'), value: '台东' },
              { name: _vm.L('花莲'), value: '花莲' },
              { name: _vm.L('澎湖'), value: '澎湖' },
              { name: _vm.L('新北'), value: '新北' },
              { name: _vm.L('金门'), value: '金门' } ]),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('大陆所在省市')}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'cityList',
            {
              initialValue: _vm.detailObj.cityList,
              rules: [{ required: true, message: _vm.L('请选择！') }],
            } ]),expression:"[\n            'cityList',\n            {\n              initialValue: detailObj.cityList,\n              rules: [{ required: true, message: L('请选择！') }],\n            },\n          ]"}],attrs:{"options":_vm.tree,"placeholder":""},on:{"change":_vm.onSelectCity}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('邮递区号')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'zipcode',
            {
              initialValue: _vm.detail.zipcode,
            } ]),expression:"[\n            'zipcode',\n            {\n              initialValue: detail.zipcode,\n            },\n          ]"}],on:{"blur":function (e) { return _vm.onBlur('zipcode', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('详细地址')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'address',
            {
              initialValue: _vm.detail.address,
            } ]),expression:"[\n            'address',\n            {\n              initialValue: detail.address,\n            },\n          ]"}],on:{"blur":function (e) { return _vm.onBlur('address', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('LINE帐号')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'line',
            {
              initialValue: _vm.detail.line,
            } ]),expression:"[\n            'line',\n            {\n              initialValue: detail.line,\n            },\n          ]"}],on:{"blur":function (e) { return _vm.onBlur('line', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('微信账号')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'wechat',
            {
              initialValue: _vm.detail.wechat,
            } ]),expression:"[\n            'wechat',\n            {\n              initialValue: detail.wechat,\n            },\n          ]"}],on:{"blur":function (e) { return _vm.onBlur('wechat', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('脸书账号')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'facebook',
            {
              initialValue: _vm.detail.facebook,
            } ]),expression:"[\n            'facebook',\n            {\n              initialValue: detail.facebook,\n            },\n          ]"}],on:{"blur":function (e) { return _vm.onBlur('facebook', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('特殊才艺')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'special_skill_detail',
            {
              initialValue: _vm.detail.special_skill_detail,
            } ]),expression:"[\n            'special_skill_detail',\n            {\n              initialValue: detail.special_skill_detail,\n            },\n          ]"}],attrs:{"rows":4},on:{"blur":function (e) { return _vm.onBlur('special_skill_detail', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('特殊饮食')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'special_diet_detail',
            {
              initialValue: _vm.detail.special_diet_detail,
            } ]),expression:"[\n            'special_diet_detail',\n            {\n              initialValue: detail.special_diet_detail,\n            },\n          ]"}],attrs:{"rows":4},on:{"blur":function (e) { return _vm.onBlur('special_diet_detail', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('特殊病史')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'special_medical_detail',
            {
              initialValue: _vm.detail.special_medical_detail,
            } ]),expression:"[\n            'special_medical_detail',\n            {\n              initialValue: detail.special_medical_detail,\n            },\n          ]"}],attrs:{"rows":4},on:{"blur":function (e) { return _vm.onBlur('special_medical_detail', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('台胞证号')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'permit_account',
            {
              initialValue: _vm.detail.permit_account,
              rules: [
                { required: true, message: _vm.L('请输入英文字母和数字！') },
                { validator: _vm.checkCharacterAndNumber, trigger: 'blur' } ],
            } ]),expression:"[\n            'permit_account',\n            {\n              initialValue: detail.permit_account,\n              rules: [\n                { required: true, message: L('请输入英文字母和数字！') },\n                { validator: checkCharacterAndNumber, trigger: 'blur' },\n              ],\n            },\n          ]"}],on:{"blur":function (e) { return _vm.onBlur('permit_account', e.target.value); }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.L('台胞证照片（必须）')}},[_c('div',{staticStyle:{"width":"200px"}},[_c('Uploader',{attrs:{"type":"image","path":_vm.path ? _vm.path : _vm.detail.permit_img},on:{"change":_vm.onChange}})],1)])],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(_vm._s(_vm.L("保存")))]),_c('a-button',{on:{"click":_vm.preview}},[_vm._v(_vm._s(_vm.L("简历预览")))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }