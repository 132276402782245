var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('input',{ref:"upload",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.onChange}}),(_vm.type === 'image')?_c('div',{staticClass:"image-uploader",on:{"click":_vm.onClick}},[(_vm.loading)?_c('div',{staticClass:"loading"},[_c('a-spin')],1):[(_vm.round)?[(_vm.path)?_c('img',{class:_vm.round ? 'round' : '',attrs:{"src":_vm.$imgCrop(_vm.path, 100, 100, 100, 100),"alt":""}}):_c('img',{class:_vm.round ? 'round' : '',attrs:{"src":_vm.$imgCrop(
              'https://s.upapi.cn/2022/03/13/7982c2127d78004abdea6ffa02a5b53e/default-id.png',
              100,
              100,
              100,
              100
            )}})]:[(_vm.path)?_c('img',{attrs:{"src":_vm.$imgCrop(_vm.path, 480, 260, 480, 260),"alt":""}}):_c('img',{attrs:{"src":_vm.$imgCrop(
              'https://s.upapi.cn/2022/03/13/7982c2127d78004abdea6ffa02a5b53e/default-id.png',
              480,
              260,
              480,
              260
            )}})],_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.L(_vm.title)))]),_c('div',[_vm._v(_vm._s(_vm.L("档案大小4MB以内")))])]],2):_vm._e(),(_vm.type === 'file')?_c('a-button',{attrs:{"loading":_vm.loading,"icon":"upload"},on:{"click":_vm.onClick}},[_vm._v(" "+_vm._s(_vm.path ? _vm.L("重新上传") : _vm.L("上传"))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }