<template>
  <div class="my-table">
    <table>
      <tr>
        <th style="flex: 2">{{ L("收藏名称") }}</th>
        <th>{{ L("收藏类型") }}</th>
        <th>{{ L("操作") }}</th>
      </tr>
      <template v-if="list.length > 0">
        <tr v-for="(item, index) in list" :key="index">
          <td style="flex: 2">
            <template v-if="item.fav_type === 'COMPANY'">
              <router-link :to="`/company-detail?uuid=${item.fav_uuid}`">
                <span style="font-weight: bold">
                  {{ L(item.fav_name) }}
                </span>
              </router-link>
            </template>
            <template v-else-if="item.fav_type === 'JOB'">
              <router-link :to="`/job-detail?uuid=${item.fav_uuid}`">
                <span style="font-weight: bold">
                  {{ L(item.fav_name) }}
                </span>
              </router-link>
            </template>
            <template v-else>
              <span style="font-weight: bold">
                {{ L(item.fav_name) }}
              </span>
            </template>
          </td>
          <td>
            <span
              style="color: #2eb792; font-weight: bold"
              v-if="item.fav_type === 'COMPANY'"
            >
              {{ L("公司") }}
            </span>
            <span
              style="color: #e85400; font-weight: bold"
              v-if="item.fav_type === 'JOB'"
            >
              {{ L("职位") }}
            </span>
          </td>
          <td>
            <a href="#" style="color: #f00" @click.prevent="removeText(item)">删除</a>
          </td>
        </tr>
      </template>
      <tr v-else>
        <td rowspan="4">
          <a-empty />
        </td>
      </tr>
    </table>

    <div class="center" v-if="total > 10">
      <a-pagination v-model="current" :pageSize="pageSize" :total="total" />
    </div>
  </div>
</template>

<script>
import { fetchCollectionList, addorCancelCollection } from "@/api/me";

export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  watch: {
    current() {
      this.getList();
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize } = this;
      fetchCollectionList({
        page: current,
        rows: pageSize,
      }).then((res) => {
        console.log("res", res);
        if (Array.isArray(res.list)) {
          this.list = res.list;
          this.total = parseInt(res.total);
        }
      });
    },

    removeText(item) {
      const that = this;

      this.$confirm({
        title: this.L("确认要删除吗？"),
        onOk() {
          addorCancelCollection({
            uuid: item.fav_uuid,
            name: item.fav_name,
            type: item.fav_type,
          }).then(() => {
            that.getList();
          });
        },
      });

    }
  },
};
</script>