<template>
  <div>
    <div style="width: 134px">
      <Uploader
        type="image"
        :round="true"
        title="建议使用证件照/职业照，可以用学生证或其他证件照片，尽量不要用生活照"
        @change="onChangeAvatar"
        :path="avatarPath ? avatarPath : detail.avatar_img"
      />
    </div>

    <a-form :form="form" :colon="false" @submit="handleSubmit">
      <a-row :gutter="24">
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item :label="L('姓名')">
            <a-input
              v-decorator="[
                'name',
                {
                  initialValue: detail.name,
                  rules: [{ required: true, message: L('请输入！') }],
                },
              ]"
              @blur="(e) => onBlur('name', e.target.value)"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item :label="L('性别')">
            <a-select
              v-decorator="[
                'sex',
                {
                  initialValue: detail.sex,
                  rules: [{ required: true, message: L('请选择！') }],
                },
              ]"
              @change="(value) => onBlur('sex', value)"
            >
              <a-select-option
                v-for="item in [
                  { name: L('男'), value: '0' },
                  { name: L('女'), value: '1' },
                ]"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item :label="L('电子邮件')">
            <a-input
              disabled
              v-decorator="[
                'email',
                {
                  initialValue: detail.email,

                  rules: [{ required: true, message: L('请输入！') }],
                },
              ]"
              @blur="(e) => onBlur('email', e.target.value)"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item :label="L('行动电话')">
            <a-input
              v-decorator="[
                'mobile',
                {
                  initialValue: detail.mobile,

                  rules: [{ required: true, message: L('请输入！') }],
                },
              ]"
              @blur="(e) => onBlur('mobile', e.target.value)"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item :label="L('去往大陆次数')">
            <a-input-number
              style="width: 100%"
              :min="0"
              :max="99"
              v-decorator="[
                'mainland_num',
                {
                  initialValue: detail.mainland_num,
                },
              ]"
              @blur="(e) => onBlur('mainland_num', e.target.value)"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item :label="L('学生类别')">
            <a-select
              v-decorator="[
                'type',
                {
                  initialValue: detail.type,
                },
              ]"
              @blur="(value) => onBlur('type', value)"
            >
              <a-select-option value="intern">在读</a-select-option>
              <a-select-option value="fresh">应届生</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label="L('两岸交流活动经验')">
            <a-textarea
              v-decorator="[
                'exchange_detail',
                {
                  initialValue: detail.exchange_detail,
                },
              ]"
              @blur="(e) => onBlur('exchange_detail', e.target.value)"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label="L('实习或工作经历')">
            <a-textarea
              v-decorator="[
                'exp_detail',
                {
                  initialValue: detail.exp_detail,
                  rules: [{ required: true, message: L('请输入') }],
                },
              ]"
              @blur="(e) => onBlur('exp_detail', e.target.value)"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label="L('参与本次实习动机')">
            <a-textarea
              v-decorator="[
                'motive_detail',
                {
                  initialValue: detail.motive_detail,
                  rules: [{ required: true, message: L('请输入') }],
                },
              ]"
              @blur="(e) => onBlur('motive_detail', e.target.value)"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label="L('对两岸关系的看法')">
            <a-textarea
              v-decorator="[
                'viewpoint_detail',
                {
                  initialValue: detail.viewpoint_detail,
                },
              ]"
              @blur="(e) => onBlur('viewpoint_detail', e.target.value)"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('是否愿意前往大陆就学/创业')">
            <a-select
              style="width: 100%"
              v-decorator="[
                'is_will_stay',
                {
                  initialValue: detail.is_will_stay,
                },
              ]"
              @change="(value) => onBlur('is_will_stay', value)"
            >
              <a-select-option
                v-for="item in [
                  { name: L('是'), value: '1' },
                  { name: L('否'), value: '0' },
                ]"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('是否有意愿担任本活动干部')">
            <a-select
              style="width: 100%"
              v-decorator="[
                'is_will_leader',
                {
                  initialValue: detail.is_will_leader,
                },
              ]"
              @change="(value) => onBlur('is_will_leader', value)"
            >
              <a-select-option
                v-for="item in [
                  { name: L('是'), value: '1' },
                  { name: L('否'), value: '0' },
                ]"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label="L('如何得知此活动讯息')">
            <a-select
              style="width: 100%"
              v-decorator="[
                'howto',
                {
                  initialValue: detail.howto ? detail.howto : activity_from,
                  rules: [{ required: true, message: L('请选择！') }],
                },
              ]"
              @change="(value) => onBlur('howto', value)"
            >
              <a-select-option
                v-for="item in activityList"
                :key="item"
                :value="item"
              >
                {{ L(item) }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label="L('备注')">
            <a-textarea
              v-decorator="[
                'remarks',
                {
                  initialValue: detail.remarks,
                },
              ]"
              @blur="(e) => onBlur('remarks', e.target.value)"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label="L('附件')">
            <Uploader
              type="file"
              @change="onChangeFile"
              :path="filePath ? filePath : detail.attach_file"
            />
            <div class="tip">建议上传zip等压缩文件</div>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit" :loading="loading">{{
                L("保存")
              }}</a-button>
              <a-button @click="preview">{{ L("简历预览") }}</a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>


<script>
import Uploader from "@/components/uploader";
import { updateResume } from "@/api/me";
import { checkUserInfoIntegrity } from "./util";
import qs from "qs";
export default {
  components: {
    Uploader,
  },

  props: {
    detail: {},
  },
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      avatarPath: "", // 头像
      filePath: "", // 附件

      activityList: [
        "自己在网上看到的（微信公众号、官网、APP等）",
        "学校老师/校友/同学推荐",
        "家人/亲友推荐",
        "其他",
      ],
      activity_from: "",
    };
  },

  mounted() {
    const activity_from = window.localStorage.getItem("activity_from");
    if (activity_from) {
      this.activityList = [activity_from];
      this.activity_from = activity_from;
    }
  },

  methods: {
    onBlur(type, value) {
      console.log(type, value);

      if (this.detail[type] === value) {
        return;
      }

      updateResume(
        qs.stringify({
          ...this.detail,
          [type]: value,
        })
      ).then((res) => {
        if (res._status === 1) {
          this.$message.success(this.L(res.info));
          this.$emit("refresh", "profile");
          // checkUserInfoIntegrity(this.detail);
        }
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          console.log(values);
          updateResume(
            qs.stringify({
              ...this.detail,
              ...values,
              avatar_img: this.avatarPath
                ? this.avatarPath
                : this.detail.avatar_img,
              attach_file: this.filePath
                ? this.filePath
                : this.detail.attach_file,
            })
          )
            .then((res) => {
              if (res._status === 1) {
                this.$message.success(this.L(res.info));
                this.$emit("refresh", "profile");

                checkUserInfoIntegrity(this.detail);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    // 改头像
    onChangeAvatar(path) {
      this.avatarPath = path;
      this.onBlur("avatar_img", path);
    },

    // 上传附件
    onChangeFile(path) {
      this.filePath = path;
      this.onBlur("attach_file", path);
    },

    preview() {
      const str = window.localStorage.getItem("resume");
      if (str) {
        const resume = JSON.parse(str);
        window.open("/portal/#/resume?uuid=" + resume.uuid, "_blank");
      } else {
        this.$message.error("请先登录");
      }
    },
  },
};
</script>

<style scoped>
.tip {
  font-size: 12px;
  color: #999;
}
</style>