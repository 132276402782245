<template>
  <div>
    <a-form :form="form" :colon="false" @submit="handleSubmit">
      <a-row :gutter="24">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('银行名称')">
            <a-input v-decorator="[
              'bank_name',
              {
                initialValue: detail.bank_name,
              },
            ]" @blur="(e) => onBlur('bank_name', e.target.value)" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('银行代码')">
            <a-input v-decorator="[
              'bank_code',
              {
                initialValue: detail.bank_code,
              },
            ]" @blur="(e) => onBlur('bank_code', e.target.value)" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('银行户名')">
            <a-input v-decorator="[
              'bank_username',
              {
                initialValue: detail.bank_username,
              },
            ]" @blur="(e) => onBlur('bank_username', e.target.value)" />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('银行账号')">
            <a-input v-decorator="[
              'bank_account',
              {
                initialValue: detail.bank_account,
              },
            ]" @blur="(e) => onBlur('bank_account', e.target.value)" />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('是否有中国信托的银行账户')">
            <a-select v-decorator="[
              'is_trust_account',
              {
                initialValue: detail.is_trust_account,
              },
            ]" @change="(value) => onBlur('is_trust_account', value)">
              <a-select-option v-for="item in [
                { name: L('是'), value: '1' },
                { name: L('否'), value: '0' },
              ]" :key="item.value" :value="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item :label="L('银行存折封面（需包含户名及账号）')">
            <div style="width: 200px">
              <Uploader type="image" @change="onChange" :path="path ? path : detail.bank_img" />
            </div>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit" :loading="loading">{{
                L("保存")
              }}</a-button>
              <a-button @click="preview">{{ L("简历预览") }}</a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import Uploader from "@/components/uploader";
import { updateResume } from "@/api/me";
import qs from "qs";
import { checkUserInfoIntegrity } from "./util";
export default {
  components: {
    Uploader,
  },

  props: {
    detail: {},
  },
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      path: "",
    };
  },

  methods: {
    onBlur(type, value) {
      console.log(type, value);

      if (this.detail[type] === value) {
        return;
      }

      updateResume(
        qs.stringify({
          ...this.detail,
          [type]: value,
        })
      ).then((res) => {
        if (res._status === 1) {
          this.$message.success(this.L(res.info));
          this.$emit("refresh", "bank");
          // checkUserInfoIntegrity(this.detail);
        }
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          console.log(values);
          updateResume(
            qs.stringify({
              ...this.detail,
              ...values,
              bank_img: this.path ? this.path : this.detail.bank_img,
            })
          )
            .then((res) => {
              if (res._status === 1) {
                this.$message.success(this.L(res.info));
                this.$emit("refresh", "bank");
                checkUserInfoIntegrity(this.detail);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    onChange(path) {
      this.path = path;
      this.onBlur("bank_img", path);
    },

    preview() {
      const str = window.localStorage.getItem("resume");
      if (str) {
        const resume = JSON.parse(str);
        window.open("/portal/#/resume?uuid=" + resume.uuid, "_blank");
      } else {
        this.$message.error("请先登录");
      }
    },
  },
};
</script>